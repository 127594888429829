const ISSUE_FIELD_MAPPING = {
  // Source field -> Target field
  'Apiphany_Environment': 'Stages',
  'Issue id': 'Issue ID',
  //'Description tags': 'Issue Details',
  'Description tags': 'Description',
  'Root Cause': 'Root cause',
  'Failure_Mode': 'Failure Mode',
  'Countermeasure': 'Counter measure',
  //'Summary': 'Issue Summary Without Tag',
  'Summary': 'Title',
  'Status': 'Status',
  'Custom field (Issue Area)': 'System',
  'Custom field (Part Number REV)': 'Part Number',
  'All_Components': 'Area Short Name',
  'Custom field (Business Impact)': 'Quantitative Impact',
  'Resolutions': 'Resolution',
  'FFF Framework': 'Fit Form or Function categorization',
  'Created': 'Date',
  'Custom field (Number or Occurrences)': 'Cases',
  'Assignee': 'Requestor',
  'Creator': 'Creator',
  'Reporter': 'Reporter',
  'Assignee Id': 'Requestor ID',
  'Specs': 'Issue Details Tag',
  'System': 'AreaList',
  "All_Comments": 'All_Comments',
  'Business Impact': 'Business Impact',
  'Delay Impact': 'Delay Impact',
  'Defect Type': 'Defect Type',
  'Phase': 'Phase',
  '_is_favorite': 'is_favorite'
};

// Helper function to map an issue object according to the defined mapping
const mapIssueFields = (source, isOriginalIssue = false) => {
  const mappedIssue = {};
  const sourceData = isOriginalIssue ? source : source._source;
  

  Object.entries(ISSUE_FIELD_MAPPING).forEach(([sourceField, targetField]) => {
    let value = sourceData[sourceField];
    
    // Special handling for AreaList
    if (targetField === 'AreaList') {
      if (value) {
        value = value.split(',')
          .map(area => area.trim())
          .filter(area => area);
      } else {
        value = [];
      }
    }

    if (targetField === 'is_favorite') {
      value = Boolean(value); 
    } else {
      value = value || '';
    }
    
    mappedIssue[targetField] = value;
  });

  // Format the 'Impact' field
  const delayImpactValue = mappedIssue['Delay Impact'] || '';
  const impactValue = mappedIssue['Impact'] || '';
  const quantitativeImpactValue = mappedIssue['Quantitative Impact'] || '';

  const formattedImpact = [
    delayImpactValue ? `Delay: ${delayImpactValue}` : null,
    impactValue ? `Impact: ${impactValue}` : null,
    quantitativeImpactValue ? `Financial Impact: ${quantitativeImpactValue}` : null
  ]
    .filter(line => line) 
    .join('\n');

  mappedIssue['Impact'] = formattedImpact;

  // Format the 'Failure Mode' field
  const defectType = mappedIssue['Defect Type'] || '';
  const failureMode = mappedIssue['Failure Mode'] || '';

  if (defectType) {
    mappedIssue['Failure Mode'] = `defect type: ${defectType}. ${failureMode}`;
  }

  // Fields that should be empty strings instead of mapped
  // mappedIssue['is_favorite'] = false;

  return mappedIssue;
};

export { ISSUE_FIELD_MAPPING, mapIssueFields }; 