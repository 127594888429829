/* 
    this is the left area of Module 2 (Search) part: issue menu, issue item and search bar
*/
import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useAppContext } from "../../../AppContext";
import IssueDetails from "./IssueDetails";
import IssueDataGrid from "./IssueDataGrid";
import "./IssueArea.css";
import SearchIssue from "../Search Area/SearchIssue"
//import Search from "../../../Components/icons/General/Search";
import { ArrowUp, ArrowDown } from "../../../Components/icons/Arrows";
import { Search, Keyboard } from "../../../Components/icons/General";
import { useNavigate } from "react-router-dom";
import { ToggleSwitch, Badge, Button, NotificationComp, TabButton } from "../../../Components";
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuSeparator,
    DropdownMenuCheckboxItem,
} from "../../../Components/ui/DropdownMenu/DropdownMenu";
import SearchResultIcon from '../../../image/rocket.png';
import { useSavedIssuesQuery, useHiddenIssuesQuery, useSharedIssuesQuery, useStarredIssuesQuery } from '../../../api/queries';
import { useQuery, useQueryClient } from 'react-query';

const EMPTY_ARRAY = [];

const IssueArea = ({ issueInfo, setSpecData, isSharedView = false }) => {
    const {
        //clickedIssue,
        setIssueDisplayArray,
        //issueDisplayArray,
        issueMenu,
        setAreaArray,
        clickedSimilarIssue,
        selectStatus,
        //areaArray,
        selectedAreas,
        allColumn,
        showsStarIssue,
        modalMessage,
        //setShowsStarIssue,
        //allStatus,
        setAllStatus,
        selectColumnArray,
        setSelectColumnArray,
        isSearchLoading,
        setStagesArray,
        issueRelativeArray,
        hasSearched,
        setHasSearched,
        highlightArray,
        setHighlightArray,
        //selectAllHighlight,
        setSelectAllHighlight,
        isOpenSpecification,
        setIsOpenSpecification,
        isSearchError, 
        setAllRequestor,
        selectRequestor, 
        setBasicValue, 
        tableViews, 
        setTableViews,
        setCheckedRows,
    } = useAppContext();

    const [firstInputIssue, setFirstInputIssue] = useState("");
    const [errorInputIssue, setErrorInputIssue] = useState("");
    const navigate = useNavigate(); // used for jump

    // the keyboard feature dropdown menu
    const [isKeyBoardOpen, setIsKeyBoardOpen] = useState(false);
    const keyBoardMenuRef = useRef(null); // the ref used for auto closed of dropdown menu
    const { data: savedIssues, isLoading: isSavedIssuesLoading } = useSavedIssuesQuery();
    const { data: hiddenIssues, isLoading: isHiddenIssuesLoading } = useHiddenIssuesQuery();
    const { data: sharedIssues, isLoading: isLoadingShared } = useSharedIssuesQuery();
    const { data: starredIssues, isLoading: isStarredIssuesLoading } = useStarredIssuesQuery();
    const queryClient = useQueryClient();

    // Memoize savedIssues to prevent unnecessary re-renders
    const savedIssuesMemo = useMemo(() => savedIssues || EMPTY_ARRAY, [savedIssues]);
    const hiddenIssuesMemo = useMemo(() => hiddenIssues || EMPTY_ARRAY, [hiddenIssues]);
    const sharedIssuesMemo = useMemo(() => sharedIssues || EMPTY_ARRAY, [sharedIssues]);
    const starredIssuesMemo = useMemo(() => starredIssues || EMPTY_ARRAY, [starredIssues]);

    useEffect(() => {
        if (savedIssues && savedIssues.length > 0) {
            setIssueDisplayArray((prevIssues) => {
                // Merge or update the issueDisplayArray with savedIssues if necessary
                return prevIssues; // Adjust as per your logic
            });
        }
    }, [savedIssues, setIssueDisplayArray]);

    useEffect(() => {
        let filteredIssues = [];
        
        // First get the base set of issues depending on the view
        if (tableViews === "Saved") {
            filteredIssues = !isSavedIssuesLoading && savedIssuesMemo ? savedIssuesMemo : [];
        } else if (tableViews === "Hidden") {
            filteredIssues = !isHiddenIssuesLoading && hiddenIssuesMemo ? hiddenIssuesMemo : [];
        } else if (tableViews === "Shared") {
            filteredIssues = !isLoadingShared && sharedIssuesMemo ? sharedIssuesMemo : [];
        } else if (tableViews === "Starred") {
            filteredIssues = !isStarredIssuesLoading && starredIssuesMemo ? starredIssuesMemo : [];
        } else {
            filteredIssues = issueRelativeArray;
        }

        // Then apply all filters, including stage filter, regardless of view
        // Filter by issueMenu (phases)
        if (issueMenu !== "All") {
            filteredIssues = filteredIssues.filter(
                (item) => item.Phase === issueMenu
            );
        }

        // Apply other existing filters
        if (selectedAreas.length !== 0) {
            filteredIssues = filteredIssues.filter((issue) => {
                const currentAreaList = issue.AreaList || issue.Area.split(', ').map(a => a.trim());
                return selectedAreas.every((area) => currentAreaList.includes(area));
            });
        }

        if (selectStatus.length !== 0) {
            filteredIssues = filteredIssues.filter((issue) =>
                selectStatus.includes(issue.Status)
            );
        }

        if (selectRequestor.length !== 0) {
            filteredIssues = filteredIssues.filter((issue) =>
                selectRequestor.includes(issue.Requestor)
            );
        }

        if (showsStarIssue === true) {
            filteredIssues = filteredIssues.filter((issue) => issue.is_favorite);
        }

        setIssueDisplayArray(filteredIssues);
    }, [
        issueMenu,
        selectedAreas,
        selectStatus,
        selectRequestor,
        showsStarIssue,
        issueRelativeArray,
        setIssueDisplayArray,
        tableViews,
        savedIssuesMemo,
        isSavedIssuesLoading,
        hiddenIssues,
        isHiddenIssuesLoading,
        hiddenIssuesMemo,
        starredIssuesMemo,
        isStarredIssuesLoading
    ]);

    /**************************************  1. the highlight drop list about words hightlight: ************************************* */
    // select and unselect one highlight item
    const toggleHighlight = (e, key) => {
        e.preventDefault();
        setHighlightArray((prevState) => {
            const newState = {
                ...prevState,
                [key]: !prevState[key],
            };
            // update the main tittle checkbox status based on user selection
            setSelectAllHighlight(Object.values(newState).every(Boolean));
            return newState;
        });
    };

    // the checkBox in the left of highlight, could select all the highlight item
    const handleSelectAll = (e) => {
        e.preventDefault();
        const newSelectAll = true;
        // update the main tittle checkBox
        setSelectAllHighlight(newSelectAll);

        // update all the status of 4 highlight item
        setHighlightArray((prevState) => {
            const newState = {};
            Object.keys(prevState).forEach((key) => {
                newState[key] = newSelectAll;
            });
            return newState;
        });
    };

    //  the checkBox in the left of highlight, click to unSelect all the highlight item
    const handleUnSelectAll = (e) => {
        e.preventDefault();
        setSelectAllHighlight(false);
        // update all the status of 4 highlight item
        setHighlightArray((prevState) => {
            const newState = {};
            Object.keys(prevState).forEach((key) => {
                newState[key] = false;
            });
            return newState;
        });
    };

    // Function: check how many selection item of highlight dropmenu was selected, and the checkbox "Add All" display checked or interminate
    const getSelectionHiglight = (selectedArr) => {
        const checkedCount = Object.values(selectedArr).filter((value) => value === true).length;
        return {
            checkedHighlight: checkedCount > 0 && checkedCount <= Object.keys(selectedArr).length,
            indeterminateHighlight: checkedCount > 0 && checkedCount < Object.keys(selectedArr).length,
        };
    };
    const { checkedHighlight, indeterminateHighlight } = getSelectionHiglight(highlightArray); 

    //Based on the issueRelativeArray(user input issue similar array) change to get the stages and area
    useEffect(() => {
        if (issueRelativeArray.length > 0) {
            //get all the area option of dropdown list
            const areaArr = [
                ...new Set(
                    issueRelativeArray.flatMap((issue) => issue.AreaList).sort(),
                ),
            ];
            setAreaArray(areaArr);

            //get all the status option of dropdown list
            const statusArr = [
                ...new Set(issueRelativeArray.map((issue) => issue.Status).sort()),
            ];
            setAllStatus(statusArr);

            //get all the statges option of issue format menu
            const stagesArr = [
                "All",
                ...new Set(issueRelativeArray.map((item) => item.Phase).sort()),
            ];
            setStagesArray(stagesArr);

            //get all the reporter name of issue format table
            const reporterName = [
                ...new Set(issueRelativeArray.map((issue) => issue.Requestor).filter((item) => item !== "").sort()),
            ]
            setAllRequestor(reporterName);
        }
    }, [issueRelativeArray, setStagesArray, setAreaArray, setAllStatus, setAllRequestor]);



    /**************************************  5. first search input onchange: ************************************* */
    const handleInputIssue = useCallback(
        (e) => {
            if (e && e.target) {
                const value = e.target.value;
                setFirstInputIssue(value);
            }
        },
        [setFirstInputIssue],
    );

    // three function used for the first search notification component
    // based on user input first search, jump and search similar issue ==> button
    const handleFirstSearch = (e) => {
        e.preventDefault();
        if (hasSearched === false) {
            setHasSearched(true);
            localStorage.setItem("hasSearched", hasSearched);
        }
        navigate(`/discover?query=${encodeURIComponent(firstInputIssue)}`);
        setBasicValue((prevTerms) => [...prevTerms,
            {
                id: firstInputIssue,
                value: firstInputIssue,
                isIssueId: !isNaN(Number(firstInputIssue)),
            },
        ]);
    }

    // based on user input first search, jump and search similar issue ==> keyboard
    const handleFirstKeyBoardSearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (hasSearched === false) {
                setHasSearched(true);
                localStorage.setItem("hasSearched", hasSearched);
            }
            navigate(`/discover?query=${encodeURIComponent(firstInputIssue)}`);
            setBasicValue((prevTerms) => [...prevTerms,
                {
                    id: firstInputIssue,
                    value: firstInputIssue,
                    isIssueId: !isNaN(Number(firstInputIssue)),
                },
            ]);
        }
    }

    // check and judge whether user is first search or not
    useEffect(() => {
        const hasSearchedLocal = localStorage.getItem("hasSearched"); //user have searched once
        if (hasSearchedLocal) {
            setHasSearched(true);
        }
    }, [setHasSearched]);


    // three function used for the error Search notification component
    const handleErrorInputIssue = useCallback(
        (e) => {
            if (e && e.target) {
                const value = e.target.value;
                setErrorInputIssue(value);
            }
        },
        [setErrorInputIssue],
    );

    const handleErrorSearch = (e) => {
        e.preventDefault();
        setBasicValue([]);
        navigate(`/discover?query=${encodeURIComponent(errorInputIssue)}`);
        setBasicValue((prevTerms) => [...prevTerms,
            {
                id: errorInputIssue,
                value: errorInputIssue,
                isIssueId: !isNaN(Number(errorInputIssue)),
            },
        ]);
    }

    // based on user input first search, jump and search similar issue ==> keyboard
    const handleErrorKeyBoardSearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setBasicValue([]);
            navigate(`/discover?query=${encodeURIComponent(errorInputIssue)}`);
            setBasicValue((prevTerms) => [...prevTerms,
                {
                    id: errorInputIssue,
                    value: errorInputIssue,
                    isIssueId: !isNaN(Number(errorInputIssue)),
                },
            ]);
        }
    }

    // the search animation effect dots numbers
    const dots = Array.from({ length: 16 });

    /**************************************  2. Key specification and the Considerations tab button function: ************************************* */
    // open the right area information: key specification and the Considerations
    const hanleOpenSpecification = useCallback(() => {
        setIsOpenSpecification(isOpenSpecification => !isOpenSpecification);
    }, [setIsOpenSpecification]);


    /**************************************  3. The keyboard dropdown menu shows all the keybaord feature: ************************************* */
    // the function: open and closed dropdown menu
    const handleOpenKeyboardMenu = () => {
        setIsKeyBoardOpen(!isKeyBoardOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (keyBoardMenuRef.current && !keyBoardMenuRef.current.contains(event.target)) {
                setIsKeyBoardOpen(false);
            }
        };

        document.addEventListener("pointerdown", handleClickOutside);
        return () => document.removeEventListener("pointerdown", handleClickOutside);
    }, []);


    /**************************************  4. The Customize Table Function Part: ************************************* */
    // Handle column selection change, save the user selected the column to display
    const handleColumnChange = (e, column) => {
        e.preventDefault();
        setSelectColumnArray((prev) => {
            let newColumns;
            if (prev.some((item) => item.field === column)) {
                // if already selected, second clicked means unselected
                newColumns = prev.filter((c) => c.field !== column);
            } else {
                // selected to add into seleected column
                newColumns = [
                    ...prev,
                    {
                        field: column,
                        headerName: column,
                        width: 200,
                        minWidth: 200,
                    },
                ];
            }
            return newColumns;
        });
    };

    // the "Selected all " function used for add all the column to display
    const handleSelectAllColumn = (e) => {
        e.preventDefault();
        setSelectColumnArray((prev) => {
            // Get the list of columns that are not yet selected
            const unselectedColumns = allColumn.filter(
                (column) => !prev.some((item) => item.field === column),
            );

            // Add only the unselected columns to the array
            const newColumns = unselectedColumns.map((column) => ({
                field: column,
                headerName: column,
                width: 200,
                minWidth: 200,
            }));

            // Return the previous selected columns along with the newly selected ones
            return [...prev, ...newColumns];
        });
    };

    // unSelected function for removed all the selection of drop down selection list
    const handleUnSelectAllColumn = (e) => {
        e.preventDefault();
        setSelectColumnArray(() => []); // Clear all selected columns
    };

    // check how many columns selected ==> whether it equals the all the columns
    const getSelectionState = (selectedArr) => {
        const checkedCount = selectedArr.length;
        return {
            checked: checkedCount > 0 && checkedCount <= allColumn.length,
            indeterminate: checkedCount > 0 && checkedCount < allColumn.length,
        };
    };
    const { checked, indeterminate } = getSelectionState(selectColumnArray);


    // the table button: All Starred Saved Shared and Hidden
    const tableChange = useCallback(
        (e) => {
            setCheckedRows(new Set())
            const selectedTab = e.target.value;
            setTableViews(selectedTab);

            if (selectedTab === 'Saved') {
                queryClient.invalidateQueries('savedIssues');
            } else if (selectedTab === 'Hidden') {
                queryClient.invalidateQueries('hiddenIssues');
            } else if (selectedTab === 'Shared') {
                queryClient.invalidateQueries('sharedIssues');
            }
        },
        // eslint-disable-next-line
        [setTableViews, queryClient]
    );


    // main render Area with HTML Tag
    return (
        <div className="issue-area"
            style={{
                width: isOpenSpecification ? 'calc(68vw - 25px)' : '100%',
                transition: 'width 0.5s ease',
            }}
        >
            {!isSharedView && <SearchIssue issueInfo={issueInfo} setSpecData={setSpecData} />}

            {!isSharedView && (
                <div className="controlArea">
                    {
                        issueRelativeArray.length > 0 ? <p className="searchResultMessage"> <img src={SearchResultIcon}
                            alt="SearchResultIcon"
                            style={{ height: "20px", width: "20px", marginRight: "6px" }}
                        />
                            {issueRelativeArray.length} similar issues found
                        </p> : <p></p>
                    }

                    <div style={{ display: "flex" }}>
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <span className="dropdown-menu-trigger-text">Highlights</span>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuCheckboxItem
                                    checked={checkedHighlight}
                                    indeterminate={indeterminateHighlight}
                                    onClick={handleSelectAll}
                                >
                                    Select all
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuSeparator />
                                <DropdownMenuCheckboxItem checked={highlightArray["Failure Mode"]} onClick={(e) => toggleHighlight(e, "Failure Mode")}>
                                    <Badge type="highlight" variant="failure-mode">
                                        Failure Mode
                                    </Badge>
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem checked={highlightArray["Components"]} onClick={(e) => toggleHighlight(e, "Components")}>
                                    <Badge type="highlight" variant="components">
                                        Components
                                    </Badge>
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem checked={highlightArray["Failure Condition"]} onClick={(e) => toggleHighlight(e, "Failure Condition")}>
                                    <Badge type="highlight" variant="failure-condition">
                                        Failure Condition
                                    </Badge>
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem checked={highlightArray["After Effects"]} onClick={(e) => toggleHighlight(e, "After Effects")} >
                                    <Badge type="highlight" variant="after-effects">
                                        After Effects
                                    </Badge>
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem checked={highlightArray["Specification"]} onClick={(e) => toggleHighlight(e, "Specification")} >
                                    <Badge type="highlight" variant="specifications">
                                        Specification
                                    </Badge>
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuSeparator />

                                <div style={{ marginLeft: "2px" }}>
                                    <Button variant="danger-link" size="medium" onClick={handleUnSelectAll}>
                                        Clear all
                                    </Button>
                                </div>
                            </DropdownMenuContent>
                        </DropdownMenu>

                        <div className="ToggleButton">
                            <p>Key Specifications</p>
                            <ToggleSwitch isOpen={isOpenSpecification} onToggle={hanleOpenSpecification} width="36px" height="18px" />
                        </div>
                    </div>
                </div>
            )}

            {!isSharedView && (
                <div className="issueItemArea">
                    <div className="ViewButtonArea">
                        <TabButton label={"All"} value={"All"} variant={"white"} isActive={tableViews === "All" ? true : false} onClick={(e) => tableChange(e)} />
                        <TabButton label={"Starred"} value={"Starred"} variant={"white"} isActive={tableViews === "Starred" ? true : false} onClick={(e) => tableChange(e)} />
                        <TabButton label={"Saved"} value={"Saved"} variant={"white"} isActive={tableViews === "Saved" ? true : false} onClick={(e) => tableChange(e)} />
                        <TabButton label={"Shared"} value={"Shared"} variant={"white"} isActive={tableViews === "Shared" ? true : false} onClick={(e) => tableChange(e)} />
                        <TabButton label={"Hidden"} value={"Hidden"} variant={"white"} isActive={tableViews === "Hidden" ? true : false} onClick={(e) => tableChange(e)} />
                    </div>

                    <div className="rightButtonArea">
                        {/* the keyboard shows feature */}
                        <div className="keyBoardButton" ref={keyBoardMenuRef} onClick={handleOpenKeyboardMenu}>
                            <Keyboard />
                        </div>
                        <div className={`keyboardDropdownMenu ${isKeyBoardOpen ? "show" : ""}`}>
                            <div className="featureItem"> Move up  <div className="KeyBoardIcon"> <ArrowUp size={20} color="#667085" /> </div> </div>
                            <div className="featureItem"> Move Down  <div className="KeyBoardIcon"> <ArrowDown size={20} color="#667085" /> </div> </div>
                            <div className="featureItem"> Mark selected row checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> space bar </div> </div>
                            <div className="featureItem"> Selected all the rows checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+A </div> </div>
                            <div className="featureItem"> UnSelected all the rows checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+D </div> </div>
                            <div className="featureItem"> Open selected row details window  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Enter </div> </div>
                            <div className="featureItem"> Mark current row as favorite <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+S </div> </div>
                            <div className="featureItem"> Move Five Rows up  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> PageUp </div> </div>
                            <div className="featureItem"> Move Five Rows Down  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> PageDown </div> </div>
                            <div className="featureItem"> Move to First Issue  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Home </div> </div>
                            <div className="featureItem"> Move to Last Issue  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> End </div> </div>
                        </div>

                        {/* the customize table button: the dropdown menu to add the columns of issue table */}
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <span className="dropdown-menu-trigger-text">Customize Table</span>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>

                                <DropdownMenuCheckboxItem
                                    checked={checked}
                                    onSelect={handleSelectAllColumn}
                                    indeterminate={indeterminate}
                                >
                                    Add all
                                </DropdownMenuCheckboxItem>

                                <DropdownMenuSeparator />
                                <div className="filterMenuScrollable">
                                    {allColumn.map((column) => (
                                        <DropdownMenuCheckboxItem
                                            key={column}
                                            checked={selectColumnArray.some(
                                                (item) => item.field === column,
                                            )}
                                            onClick={(e) => handleColumnChange(e, column)}
                                        >
                                            {column}
                                        </DropdownMenuCheckboxItem>
                                    ))}
                                </div>
                                <DropdownMenuSeparator />

                                <div style={{ marginLeft: "2px" }}>
                                    <Button variant="danger-link" size="medium" onClick={handleUnSelectAllColumn}>
                                        Clear all
                                    </Button>
                                </div>
                            </DropdownMenuContent>
                        </DropdownMenu>

                    </div>
                </div>
            )}

            {/* The Issue Data Table: Display all the issue datas */}
            <div className="issue-areaFormat">
                {(isSearchLoading ||
                    (tableViews === "Saved" && isSavedIssuesLoading) ||
                    (tableViews === "Hidden" && isHiddenIssuesLoading) ||
                    (tableViews === "Shared" && isLoadingShared) ||
                    (tableViews === "Starred" && isStarredIssuesLoading)) ? (
                    <div className="fade-in">
                        <div className="dots-loader">
                            {dots.map((_, index) => (
                                <div
                                    key={index}
                                    className="dot"
                                    style={{
                                        transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                                        animationDelay: `${index * 0.125}s`
                                    }}
                                />
                            ))}
                        </div>
                        <p style={{ marginLeft: "8px" }}>
                            {isSearchLoading ? "Apiphany AI is Searching for Relative Issue..." :
                                (tableViews === "Saved" && isSavedIssuesLoading) ? "Loading saved issues..." :
                                (tableViews === "Hidden" && isHiddenIssuesLoading) ? "Loading hidden issues..." :
                                (tableViews === "Starred" && isStarredIssuesLoading) ? "Loading starred issues..." :
                                "Loading shared issues..."}
                        </p>
                    </div>
                    ) : isSharedView ? (
                        <IssueDataGrid isSharedView={true} tableViews={tableViews} />
                    ) : (
                        tableViews === 'Saved' || tableViews === 'Hidden' || 
                        tableViews === 'Shared' || tableViews === 'Starred' ? (
                            <IssueDataGrid tableViews={tableViews} />
                        ) : hasSearched === false ? (
                            <div className="search-input-area">
                                <NotificationComp
                                    errorTitle="Search for your first issue here"
                                    onChange={handleInputIssue}
                                    onKeyDown={handleFirstKeyBoardSearch}
                                    onClick={(e) => handleFirstSearch(e)}
                                    Icon={Search}
                                />
                            </div>
                        ) : isSearchError ? (
                            <div className="search-input-area">
                                <NotificationComp
                                    errorTitle="There was a problem processing your request..."
                                    errorText={modalMessage}
                                    hasSearchBar={true}
                                    contactTitle="Contact us"
                                    contactText="We're here to help."
                                    contactEmail="Support@apiphany.ai"
                                    Icon={Search}
                                    onChange={handleErrorInputIssue}
                                    onClick={(e) => handleErrorSearch(e)}
                                    onKeyDown={handleErrorKeyBoardSearch}
                                />
                            </div>
                        ) : issueRelativeArray.length === 0 ? (
                            <div className="search-input-area">
                                <NotificationComp
                                    errorTitle="Enter issue numbers or keywords in the 'Discover Occurrences' field to start your search adventure!"
                                    hasSearchBar={false}
                                    Icon={ArrowUp}
                                />
                            </div>
                        ) : (
                            <IssueDataGrid tableViews={tableViews} />
                        )
                    )}
            </div>

            {/* open the relative issue details pop window */}
            {clickedSimilarIssue !== null && <IssueDetails />}


        </div>
    );
};

export default IssueArea;
