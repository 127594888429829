import React from 'react';
import './Spinner.css';

const Spinner = ({ size = 'small' }) => {
  const dotCount = 16;
  const dots = Array.from({ length: dotCount });
  
  // Adjust the scale based on the size prop
  const sizeStyle = size === 'small' ? { transform: 'scale(0.4)' } : {};

  return (
    <div className="spinner" style={sizeStyle}>
      <div className="spinner-dots-loader">
        {dots.map((_, index) => (
          <div
            key={index}
            className="spinner-dot"
            style={{
              transform: `rotate(${index * (360 / dotCount)}deg) translateY(-20px)`,
              animationDelay: `${index * 0.05}s`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Spinner; 