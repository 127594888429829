import React, { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import IssueArea from "../Module Discover/Issue Table/IssueArea";
import { useSharedContentQuery } from '../../api/queries';
import { useAppContext } from "../../AppContext";
import toast from 'react-hot-toast';

const SharedView = () => {
  const { "*": token } = useParams();
  const navigate = useNavigate();
  const { 
    setIssueDisplayArray, 
    setIssueRelativeArray,
    setHasSearched
  } = useAppContext();
  
  const sharedContentMutation = useSharedContentQuery(token);

  useEffect(() => {
    if (!token) {
      navigate('/discover');
      return;
    }

    sharedContentMutation.mutate(undefined, {
      onSuccess: (data) => {
        setIssueDisplayArray(data.issues);
        setIssueRelativeArray(data.issues);
        // setHasSearched(true);
      },
      onError: () => {
        toast.error('Invalid token');
        navigate('/discover');
      }
    });
  }, 
  [token]);

  if (sharedContentMutation.isLoading) {
    return (
      <div className="fade-in">
        <div className="dots-loader">
          {Array.from({ length: 16 }).map((_, index) => (
            <div
              key={index}
              className="dot"
              style={{
                transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                animationDelay: `${index * 0.125}s`
              }}
            />
          ))}
        </div>
        <p style={{ marginLeft: "8px" }}>Loading shared issues...</p>
      </div>
    );
  }

  return (
    <div className="discover">
      <IssueArea isSharedView={true} />
    </div>
  );
};

export default SharedView; 