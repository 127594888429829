import React, { useEffect, useState, useRef } from "react";
import './style.css'
//import { useAppContext } from "../../../AppContext";
import { FormHeader } from "../../../index";
import { InputFields, Button } from "../../../Components";
import companyLogoWithoutName from "../../../image/ApiphanyLogo/ApiphanyIconWithoutName.jpg";
import ModuleImage from "../../../image/ApiphanyLogo/Mockup modules.png";
import { useVerifyResetTokenMutation, useResetPasswordMutation } from "../../../api/queries";
import { useNavigate } from "react-router-dom";
import { SuccessNotice } from "../../../Components";
import { Check } from "../../../Components/icons/General";

const UpdaePassword = ({onPasswordUpdate}) => {
    const navigate = useNavigate();
    const newPasswordRef = useRef(null); // the new password ref
    const passwordConfirmRef = useRef(null); // the passwordConfirmation ref

    const handleKeyDown = (e, field) => {
        if (e.key === "Enter") {
            e.target.blur(); // jump to outside of active input
            // check user input was correct for email format or not
            if (field === "newPassword") {
                passwordConfirmRef.current.focus();
            } else if (field === "confirmPassword") {
                handleUpdatePassword();
            }
        }
    };

    // call the backend api in here, check the token is correct to shows the update password page or not
    // yes ==> update passwored page ==> navigate ('/update_password')
    // no ==> send email page ==> navigate('/forget_password')
    const currToken = window.location.pathname.split('/').pop(); // just sent the token to the backend
    //console.log(currToken);
    const verifyResetToken = useVerifyResetTokenMutation(); // call the api to check the token is correct or not
    //console.log(verifyResetToken);

    // useffect call the API check the token is correct or not
    useEffect(() => {
        verifyResetToken.mutate(currToken, {
            onError: () => {
                navigate('/forgot_password');
            }
        });
    }, // eslint-disable-next-line 
    [currToken]); 

  
    // there is api function: send the new password and token to the backend update password
    // if sucess ==> success notice of update password ==> back to logIn page
    // format password object
    const [formData, setFormData] = useState({
        newPassword: "",
        confirmPassword: "",
    });

    // the password requirnment
    const [passwordValidation, setPasswordValidation] = useState({
        hasMinLength: false,
        hasUpperCase: false,
        hasNumberAndSpecial: false,
        passwordsMatch: false,
    });

    const [updateStatus, setUpdateStatus] = useState({
        loading: false,
        error: null,
        success: false,
    });

    const updatePasswordMutation = useResetPasswordMutation();

    // check whether the new password requriment test
    const checkPasswordRequirements = (password) => {
        return {
            hasMinLength: password.length >= 8,
            hasUpperCase: /[A-Z]/.test(password),
            hasNumberAndSpecial:
                /[0-9]/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password),
            passwordsMatch: password === formData.confirmPassword,
        };
    };

    // function to update the new password
    const handlePasswordChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === "newPassword") {
            setPasswordValidation(checkPasswordRequirements(value));
        } else if (name === "confirmPassword") {
            setPasswordValidation((prev) => ({
                ...prev,
                passwordsMatch: value === formData.newPassword,
            }));
        }
    };

    // check whether all the requirment was great to update it
    const isPasswordValid = () => {
        return Object.values(passwordValidation).every((value) => value === true);
    };

    // the async function ==> now used for mock the data changed from backend database
    const handleUpdatePassword = async () => {
        try {
            setUpdateStatus({ loading: true, error: null, success: false });

            const result = await updatePasswordMutation.mutateAsync({
                token: currToken,
                password: formData.newPassword,
                password_confirmation: formData.confirmPassword,
            });

            console.log(result); // used for test update password sucess or not

            // Check if the update was successful
            if (result.message === "Password has been reset successfully") {
                setUpdateStatus({ loading: false, error: null, success: true });

                // Clear form data on success
                setFormData({
                    newPassword: "",
                    confirmPassword: "",
                });

                // Update password in parent component if needed
                if (onPasswordUpdate) {
                    onPasswordUpdate(formData.newPassword);
                }
            } else {
                // Handle any other status
                setUpdateStatus({
                    loading: false,
                    error: result.message || "Failed to update password",
                    success: false
                });
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message ||
                error.response?.data?.errors?.password?.[0] ||
                "Failed to update password. Please try again.";
            setUpdateStatus({ loading: false, error: errorMessage, success: false });
        }
    };

    return <div className="container">

        <div className="updatePasswordComp">
            {(updateStatus.success === true) ? <SuccessNotice
                title="Success Update the Password"
                Icon={Check}
                navigate={navigate}
                hasJumpLink={true}/>  
                :
                <div className="updatePasswordArea">
                    <FormHeader
                        title="Reset password"
                        subtitle="Enter new password to reset the password on your account. We'll ask for this password whenever you sign in"
                        className="forgotHeader"
                        companyImage={companyLogoWithoutName}
                    />

                    <div className="passwordInputArea">
                        <InputFields
                            height="24px"
                            hint=""
                            label="New password"
                            name="newPassword"
                            placeholder="Please Enter New password"
                            type={isPasswordValid ? "generalWithBlack" : "errorWithBlack"}
                            hasIcon={isPasswordValid ? false : true}
                            isPassword={true}
                            isRequired={true}
                            value={formData.newPassword}
                            onChange={handlePasswordChange}
                            inputRef={newPasswordRef}
                            onKeyDown={(e) => handleKeyDown(e, "newPassword")}
                        />
                        <div className="updatePassword-requirements">
                            <div
                                className={`updatePassword-requirement ${formData.newPassword ? (passwordValidation.hasMinLength ? "valid" : "notValid") : ""}`}
                            >
                                Password must have at least 8 characters
                            </div>
                            <div
                                className={`updatePassword-requirement ${formData.newPassword ? (passwordValidation.hasUpperCase ? "valid" : "notValid") : ""}`}
                            >
                                Password must have at least one upper character (A-Z)
                            </div>
                            <div
                                className={`updatePassword-requirement ${formData.newPassword ? (passwordValidation.hasNumberAndSpecial ? "valid" : "notValid") : ""}`}
                            >
                                Password must have at least one number (0-9) and one special character
                            </div>
                        </div>
                    </div>

                    <InputFields
                        height="18px"
                        hint="Passwords must match"
                        label="Confirm new password"
                        name="confirmPassword"
                        placeholder="Please confirm your new password"
                        type={
                            formData.confirmPassword &&
                                passwordValidation.passwordsMatch === false
                                ? "errorWithBlack"
                                : "generalWithBlack"
                        }
                        hasIcon={
                            formData.confirmPassword &&
                                passwordValidation.passwordsMatch === false
                                ? true
                                : false
                        }
                        isPassword={true}
                        isRequired={true}
                        width="334px"
                        value={formData.confirmPassword}
                        onChange={handlePasswordChange}
                        inputRef={passwordConfirmRef}
                        onKeyDown={(e) => handleKeyDown(e, "confirmPassword")}
                    />

                    <Button
                        variant="primary"
                        size="medium"
                        width={"100%"}
                        onClick={handleUpdatePassword}
                    >
                        Reset Password
                    </Button>
                </div>
            }
        </div>

        <div className="image-container">
            <img src={ModuleImage} alt="All Module" className="imageBig" />
        </div>
    </div>
}

export default UpdaePassword;