/*
  this is the search area of module 2 (Search) page: including search bar, dropdownlist, and search button
*/
import React, { useEffect, useCallback, useState } from "react";
import { useAppContext } from "../../../AppContext";
import { useSearchMutation, useAllIssueID } from "../../../api/queries";
import "./searchLayout.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, TabButton } from "../../../Components/index";
import SearchField from "../../../Components/ui/SearchField/SearchField";
import { mapIssueFields } from '../../../config/issueMapping';

const SearchIssue = ({ issueInfo, setSpecData }) => {
  // get the data from the context api store
  const {
    //clickedIssue,
    inputIssue,
    setInputIssue,
    setIssueMenu,
    //setIsOpen,
    setIsSearchError,
    setStagesArray,
    setModalMessage,
    //issueArray,
    setIssueArray,
    setIsSearchLoading,
    setSelectedAreas,
    setSelectStatus,
    //selectStatus,
    //selectedAreas,
    setClickedSimilarIssue,
    stagesArray,
    issueMenu,
    //showDropDown,
    setShowDropDown,
    // setStarIssueArray,
    // showsStarIssue,
    setShowsStarIssue,
    setClickedIssue,
    setIssueRelativeArray,
    hasSearched,
    setHasSearched,
    basicValue,
    setCurrentRowIndex,
    setTableViews,
  } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParam = searchParams.get("query") || "";

  const [localInput, setLocalInput] = useState(queryParam);
  const searchMutation = useSearchMutation();
  const [allIssueID, setAllIssueId] = useState([]);

  const dataIssueIDs = useAllIssueID();
  
  //based on the backend data get all issue IDs
  useEffect(() => {
    if(dataIssueIDs.data !== undefined && dataIssueIDs.data != null){
      setAllIssueId(dataIssueIDs.data.data);
    }
  }, [dataIssueIDs])

  //console.log(allIssueIDs.data.data);

  // Add state for filter options
  const [filterOptions, setFilterOptions] = useState({
    status: [],
    system: [],
    assignee: [],
    stages: [],
    areas: [],
  });

  /**************************************  1. the filter stages menu part: ************************************* */
  /*
  const { data: searchResults, isLoading: isSearchLoading, error: searchError } = useSearchQuery(inputIssue, 'Issues');

  useEffect(() => {
    if (searchResults) {
      setIssueRelativeArray(searchResults);
    }
  }, [searchResults, setIssueRelativeArray]);
  */
  // used for to filter and find the issue array could match user input question
  useEffect(() => {
    if (issueInfo && issueInfo.related_issues) {
      const newArray = issueInfo.related_issues.filter((item) => {
        const searchIssue = localInput && localInput.toString();
        const dataIssue = item._source["Issue id"].toString();
        return searchIssue && dataIssue.startsWith(searchIssue);
      });
      setIssueArray(newArray);
    }
  }, [localInput, issueInfo, setIssueArray]);

  // the filter menu of search field item select function
  const handleSearchItemClick = useCallback(
    async (query) => {
      setIsSearchLoading(true);
      setShowsStarIssue(false);
      setTableViews("All"); // if user start a new search, will back to default page ("All")

      try {
        const result = await searchMutation.mutateAsync(query);
        //console.log(result); // ==> used to check what data we get from the database 

        if (result.original_issue) {
          const updatedIssueItem = mapIssueFields(result.original_issue, true);
          setClickedIssue(updatedIssueItem);
          setInputIssue(result.original_issue["Issue id"]);

          const mappedRelatedIssues = Array.isArray(result.related_issues)
            ? result.related_issues.map((issue) => mapIssueFields(issue))
            : [];
          setIsSearchError(false);
          setIssueRelativeArray(mappedRelatedIssues);

          // get all the phases of current data
          const stagesArr = [
            "All",
            ...new Set(mappedRelatedIssues.map((issue) => issue.Phase)),
          ]
            .filter(Boolean)
            .sort();
          setStagesArray(stagesArr);
          setSpecData(result.specs || []);

          // Extract unique filter options from the results
          const extractedFilters = {
            status: [
              ...new Set(
                result.related_issues
                  .filter((issue) => issue._source?.Status != null)
                  .map((issue) => issue._source.Status),
              ),
            ],

            system: [
              ...new Set(
                result.related_issues
                  .filter((issue) => issue?._source["System"] != null)
                  .map((issue) => issue._source["System"].split(","))
                  .flat()
                  .map((str) => str.trim()) // renove the empty space of each item
              ),
            ],


            requestor: [
              ...new Set(
                result.related_issues
                  .filter((issue) => issue._source?.Assignee != null)
                  .map((issue) => issue._source.Assignee),
              ),
            ],
          };
          setFilterOptions(extractedFilters);
        } else {
          setModalMessage(
            "Sorry, cannot find this input number, please try to input another Issue Number or Part Number",
          );
          setIsSearchError(true);
          setClickedIssue(null);
          setIssueRelativeArray([]);
          setStagesArray(["All"]);
        }
      } catch (error) {
        console.error("Search error:", error);
        setModalMessage(
          "An error occurred during the search. Please try again.",
        );
        setIsSearchError(true);
        setClickedIssue(null);
        setIssueRelativeArray([]);
        setStagesArray(["All"]);
      } finally {
        setIsSearchLoading(false);
      }
    },
    // eslint-disable-next-line
    [searchMutation, setSpecData],
  );

  useEffect(() => {
    // ==> check the queryParam in every time  
    // console.log(queryParam);  
    const performSearch = async () => {
      if (queryParam && queryParam !== inputIssue) {
        setInputIssue(queryParam);
        setLocalInput(queryParam);
        await handleSearchItemClick(queryParam);
      }
    };

    performSearch();
    // eslint-disable-next-line
  }, [queryParam]);

  // use aysnc to mock the search process and ==> call the Apiphany Search API in the future  <=====
  // const mockSearch = (id) => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve([`Similar issue array result for ${id}`]);
  //     }, 1200); // mock the search
  //   });
  // };
  // use aysnc to mock the search process and ==> call the Apiphany Search API in the future  <=====

  /**************************************  2. the filter stages menu part: ************************************* */
  const menuDefaultArray = [
    "All",
    "Planning",
    "R&D",
    "Product Engineering",
    "Manufacturing",
    "Field Testing",
    "Maintenance"
  ];

  //used for the switch menu page (All, Planning, R&D, Produce Engineering, Manufacturing, and Field & Warranty)
  const changePart = useCallback(
    (e) => {
      setIssueMenu(e.target.value);
    },
    [setIssueMenu],
  );

  // based on user search, jump into the search result query with call api
  const handleSubmit = (e) => {
    e.preventDefault();
    if (hasSearched === false) {
      setHasSearched(true);
      localStorage.setItem("hasSearched", hasSearched);
    }
    setCurrentRowIndex(0);
    navigate(`/discover?query=${encodeURIComponent(localInput)}`);
  };

  // the keyboard press enter to search for frist issue search
  const handleKeyBoradSubmit = (queryString) => {
    if (hasSearched === false) {
      setHasSearched(true);
      localStorage.setItem("hasSearched", hasSearched);
    }
    setCurrentRowIndex(0);
    navigate(`/discover?query=${encodeURIComponent(queryString)}`);
  };

  // the search function: through user input search the all the relative issue data
  const handleSearch = (e, searchTerms, isEnterPressed) => {
    if (searchTerms) {
      const queryString = searchTerms
        .map((term) => (term.isIssueId ? term.value : term.value))
        .join(" ");

      setLocalInput(queryString);
      setShowDropDown(queryString.trim() !== "");

      if (isEnterPressed) {
        handleKeyBoradSubmit(queryString);
      }

      if (queryString.trim() === "") {
        setClickedIssue(null);
        setClickedSimilarIssue(null);
      }

      window.analytics.track('Issue Query', {
        query: queryString
      });

      setCurrentRowIndex(0);
    }
  };

  // the main render area
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        background: "white",
      }}
    >

      <div className="searchBox">
        <p style={{ userSelect: "none", whiteSpace: "nowrap" }}>Discover Occurrences</p>
        <div
          style={{
            display: "flex",
            outline: "0",
            flex: "1",
            alignItems: "center",
            paddingRight: "0",
            position: "relative",
            gap: "8px",
          }}
        >
          <div style={{ flex: 1 }}>
            <SearchField
              placeholder="Add tags separated by commas to begin your search"
              onSearch={handleSearch}
              initialTerms={
                queryParam
                  ? [
                    {
                      id: Date.now().toString(),
                      value: queryParam,
                      isIssueId: !isNaN(Number(queryParam)),
                    },
                  ]
                  : []
              }
              filterOptions={filterOptions}
              setSelectedAreas={setSelectedAreas}
              setSelectStatus={setSelectStatus}
              basicValue={basicValue}
              dropDownMenuData={allIssueID}
            />
          </div>
          <Button size="medium" onClick={handleSubmit} className="searchButton">
            Search
          </Button>
        </div>
      </div>

      {/* the issue menu page */}
      <div className="tabs-container">
        <div
          style={{ display: "flex", justifyContent: "flex-start", gap: "4px" }}
        >
          {stagesArray.length > 0 &&
            menuDefaultArray.map((stages) => {
              const isStageAllowed = stagesArray.includes(stages);
              return (
                <TabButton
                  key={stages}
                  label={stages}
                  value={stages}
                  variant="black"
                  isActive={issueMenu === stages ? true : false}
                  onClick={(e) => isStageAllowed && changePart(e)}
                  isDisable={!isStageAllowed}
                />
              );
            })}

          {stagesArray.length === 0 &&
            menuDefaultArray.map((stages) => {
              return (
                <TabButton
                  key={stages}
                  label={stages}
                  value={stages}
                  isActive={issueMenu === stages ? true : false}
                  onClick={(e) => changePart(e)}
                  isDisable={false}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SearchIssue;
