import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL + "/api";
//const baseURL = "http://localhost:8000/api";
const api = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
