/*
  this is login component: to check the user enter the username and password
*/
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./layout.css";
import { useAppContext } from "../../../AppContext";
import companyLogoWithoutName from "../../../image/ApiphanyLogo/ApiphanyIconWithoutName.jpg";
import { FormHeader } from "../../../index";
import { useNavigate } from "react-router-dom";
import { InputFields, Button } from "../../../Components";
import { Checkbox } from "../../../Components/ui/Checkbox/index";
import { useLoginMutation } from "../../../api/queries";
import useAuthStore from "../../../stores/useAuthStore";

const Login = ({ onLogin }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState(""); // the email that user input for logIn or change password
  const [password, setPassword] = useState(""); // the password that user input to logIn
  const [error, setError] = useState(false); // the error of logIn
  //const [errorState, setErrorState] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // the error message about logIn: wrong password or do not have email

  const {
    setRegistrationSuccess,
    setSelectedMenuItem,
    isRememberMe,
    setIsRemeberMe,
  } = useAppContext();
  const { isAuthenticated, setAuth } = useAuthStore();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation

  // used "Enter" jump to next input field
  const handleKeyDown = (e, field) => {
    if (e.key === "Enter") {
      e.target.blur(); // jump to outside of active input
      // check user input was correct for email format or not
      if (field === "email") {
        passwordRef.current.focus();

        if (!emailRegex.test(email)) {
          setErrorMessage("invalid email")
        } 
      } else if (field === "password") {
        handleSubmit(e);
      }
    }
  };

  const loginMutation = useLoginMutation();

  const from = location.state?.from || "/";

  useEffect(() => {
    if (isAuthenticated) {
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, from]);

  // check whether local storage have remebered the email and account
  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setPassword(rememberedPassword);
      setIsRemeberMe(true);
    }
  }, [setIsRemeberMe]);

  // the logIn submitted function
  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(false);
    setErrorMessage("");

    try {
      const response = await loginMutation.mutateAsync({ email, password });

      setRegistrationSuccess(false);
      setSelectedMenuItem("discover");

      if (isRememberMe) {
        localStorage.setItem("rememberedEmail", email);
        localStorage.setItem("rememberedPassword", password);
      } else {
        localStorage.removeItem("rememberedEmail");
        localStorage.removeItem("rememberedPassword");
      }

      const user = {
        id: response.user.id,
        firstName: response.user.first_name,
        lastName: response.user.last_name,
        email: response.user.email,
        type: response.user.type,
      };

      setAuth(user, response.access_token);

      onLogin(user);

      navigate(from, { replace: true });
    } catch (error) {
      setError(true);
      setErrorMessage(
        error.response?.data?.message || "An error occurred during login.",
      );
    }
  };

  //function to save remember account or not
  const handleClickedRemember = () => {
    setIsRemeberMe(!isRememberMe);
  };

  // the function to start input email ==> update password
  const handleStartInputEmail = () => {
    navigate('/forgot_password');
  }

  return (
    <div className="container">

      <div className="loginComp">
        <div className="login-container">
          <FormHeader
            title="Log in to your account"
            subtitle="Welcome back! Please enter your details."
            className="form-header"
            companyImage={companyLogoWithoutName}
          />

          <div className="formArea">
            <InputFields
              height="24px"
              isRequired={true}
              name="email"
              hint=""
              errorMessage={
                errorMessage === "invalid email"
                  ? "Please enter a valid email address"
                  : errorMessage !== "" ? "Incorrect email or password. Please try again" : ""
              }
              label="Email"
              placeholder="Enter your email"
              type={(error || errorMessage === "invalid email") ? "errorWithBlack" : "generalWithBlack"}
              width="334px"
              value={email}
              hasIcon={(error || errorMessage === "invalid email") ? true : false}
              onChange={(e) => setEmail(e.target.value)}
              isPassword={false}
              onKeyDown={(e) => handleKeyDown(e, "email")}
              inputRef={emailRef}
            />

            <InputFields
              height="24px"
              name="password"
              hint=""
              errorMessage={
                error
                  ? "Incorrect email or password. Please try again"
                  : ""
              }
              label="Password"
              isRequired={true}
              placeholder="Enter your password"
              type={(error) ? "errorWithBlack" : "generalWithBlack"}
              width="334px"
              hasIcon={(error) ? true : false}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, "password")}
              isPassword={true}
              inputRef={passwordRef}
            />


            <div className="addtionArea">
              <Checkbox
                id="default-checkbox"
                label="Remember me"
                checked={isRememberMe ? true : false}
                onChange={handleClickedRemember}
                size="sm"
                type="checkbox"
              />

              <label
                className="jumpSource"
                onClick={handleStartInputEmail}
              >
                Forgot Password?
              </label>
            </div>

            <Button onClick={handleSubmit} type="submit" variant="primary" size="small" width="100%">
              Sign in
            </Button>
          </div>
        </div>
      </div>


      <div className="image-container">
        
      </div>
    </div>
  )
};

export default Login;
