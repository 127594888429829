// the Success Notice used for forget password: shows email correct and changed successed
import React from "react";
import PropTypes from "prop-types";
import "./SuccessNotice.css";
import { IconsMisc } from "../../icons";
import { ArrowRight } from "../../icons/Arrows";

const SuccessNotice = ({
    title,
    subTitle,
    Icon,
    hasJumpLink = false,
    defaultWidth = "480px", 
    navigate,
}) => {
    // const navigate = useNavigate(); // Initialize useNavigate ==> used for jump back to login page

    const handleBack = () => {
        navigate("/"); // Redirect to login page
    }

    return (
        <div className="successNoticeArea" style={{ width: defaultWidth }}>
            <div className="titleTextArea">
                <IconsMisc Icon={Icon} size="lg" color="green"/>
                <span className="title" style={{marginTop: "12px"}}>{title}</span>
                <span className="subTitle">{subTitle}</span>

                {hasJumpLink && <span className="jumpText" onClick={handleBack}>Sign in now <ArrowRight size={14} color="var(--blue-600)"/> </span>}
            </div>
             
        </div>
    );
};

SuccessNotice.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    Icon: PropTypes.elementType,
    hasJumpLink: PropTypes.bool,
    defaultWidth: PropTypes.string,
    navigate: PropTypes.func,
};

export default SuccessNotice;